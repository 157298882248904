body{
    background-color: #1A2027;
    color:white;
    font-family: Arial, Helvetica, sans-serif;

    margin: 0;
    padding: 0;
}
a{
    text-decoration: none;
    color: white;
}
#header img{
    border-radius: 50%;
    width: 50%;
    padding-bottom: 10px;
    object-fit: contain;
}
hr{
    border-color:red;
    width: 50%;
    opacity: 50%;
}

.header{
    position: relative;
    min-height: 75vh;
    width: 100%;
    align-items: center;
}

.header::before{
    content: "";
    position: absolute;
    background: url("./images/header2.jpg");
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 75vh;
    filter: brightness(0.3);
}

.header h1{
    position: absolute;
    color:#bf3535;
    font-size: 4rem;
    font-weight: bold;
    padding-top: 1.5em;
    left:0.5em;
}
.header h2{
    position: absolute;
    color: #bf3535;
    margin-top: 14em;
    padding: 1rem;
    right:1em;
    font-size: 2rem;
}

#logo{
    border-radius: 15%;
}
@media only screen and (max-width: 600px) {
    .header::before{

    }
  }